import {ExperimentsBag} from '@wix/wix-experiments'
import {SCHEDULE_SECTION_ID} from '@wix/wix-events-commons-statics'
import {AppDescriptor, PageSettingsTabType, AppManifest} from '@wix/platform-editor-sdk'
import {
  EVENT_MANAGE_EVENTS,
  EVENT_DELETE_EVENTS,
  APP_ACTION_MANAGE_EVENTS,
  APP_ACTION_CREATE_EVENT,
  APP_ACTION_OPEN_EVENT_PAGES,
  APP_ACTION_ADD_EVENT_ELEMENTS,
} from './events'
import {EVENTS_APP_DEF_ID} from './constants'

export const getAppManifestFactory = (
  getT: Function,
  getLocale: Function,
  getAppToken: Function,
  getSdk: Function,
  getExperiments: () => ExperimentsBag,
  isResponsive: () => boolean,
) => async () => {
  const t = getT()
  const locale = getLocale()
  const appToken = getAppToken()
  const sdk = getSdk()
  const responsive = isResponsive()

  const {instance} = await sdk.document.tpa.app.getDataByAppDefId(appToken, EVENTS_APP_DEF_ID)

  const appDescriptor: AppDescriptor = {
    mainActions: [
      {
        title: t('appManagerManageEvents'),
        actionId: APP_ACTION_MANAGE_EVENTS,
        icon: 'appManager_settingsAction',
      },
    ],
    customActions: [
      {
        title: t('appManagerCreateEvent'),
        type: 'dashboard',
        icon: 'appManager_settingsAction',
        actionId: APP_ACTION_CREATE_EVENT,
      },
      {
        title: t('appManagerOpenEventPages'),
        type: 'editorActions',
        icon: 'appManager_pagesAction',
        actionId: APP_ACTION_OPEN_EVENT_PAGES,
      },
      {
        title: t('appManagerAddEventElements'),
        type: 'editorActions',
        icon: 'appManager_addElementsAction',
        actionId: APP_ACTION_ADD_EVENT_ELEMENTS,
      },
    ],
    defaultActions: {
      upgrade: {
        upgradeType: 'SITE_UPGRADE',
        upgradeText: t('appManagerUpgradeText'),
        upgradeLinkText: t('appManagerUpgradeLinkText'),
      },
      learnMoreKB: '2f8a99f2-0e47-4c5f-bc17-4a768db57c8f',
    },
  }

  return {
    appDescriptor,
    pages: {
      applicationSettings: {
        default: {
          displayName: t('pagesPanelEventsSectionTitle'),
          helpId: '9e9821b7-9e29-4ca0-ad92-0bd08c1d15ec',
        },
      },
      applicationActions: {
        default: [
          {
            title: t('pagesPanelEventsSectionManage'),
            event: EVENT_MANAGE_EVENTS,
            icon: 'settingsAction',
          },
          {
            title: t('pagesPanelEventsSectionDelete'),
            event: EVENT_DELETE_EVENTS,
            icon: 'deleteRadio',
          },
        ],
      },
      pageActions: {
        default: [],
        [SCHEDULE_SECTION_ID]: [],
      },
      pageSettings: {
        default: [
          {
            title: t('pagesPanelEventsDetailsTabPageInfoTitle'),
            url: `https://editor.wixapps.net/_api/wix-one-events-server/html/page-panel-info?locale=${locale}&instance=${instance}`,
            type: 'page_info' as PageSettingsTabType,
            helpId: '9e9821b7-9e29-4ca0-ad92-0bd08c1d15ec',
          },
          {
            title: t('pagesPanelEventsDetailsTabLayoutsTitle'),
            type: 'layout' as PageSettingsTabType,
            helpId: 'ecea98ce-90c7-48e6-ab40-207d6cc2758d',
            url: '',
          },
          {
            title: t('pagesPanelEventsDetailsTabPermissionsTitle'),
            type: 'permissions' as PageSettingsTabType,
            helpId: responsive ? 'beb7ca82-41b0-443c-89b5-1534d0398568' : 'b893d6b5-13b3-44d0-9e11-46eecd862a87',
            url: '',
          },
        ],
        [SCHEDULE_SECTION_ID]: [
          {
            title: t('pagesPanelEventsDetailsTabPageInfoTitle'),
            url: `https://editor.wixapps.net/_api/wix-one-events-server/html/page-panel-info?locale=${locale}&instance=${instance}&page-type=schedule`,
            type: 'page_info' as PageSettingsTabType,
            helpId: '9e9821b7-9e29-4ca0-ad92-0bd08c1d15ec',
          },
          {
            title: t('pagesPanelEventsDetailsTabLayoutsTitle'),
            type: 'layout' as PageSettingsTabType,
            helpId: 'ecea98ce-90c7-48e6-ab40-207d6cc2758d',
            url: '',
          },
          {
            title: t('pagesPanelEventsDetailsTabPermissionsTitle'),
            type: 'permissions' as PageSettingsTabType,
            helpId: '9e9821b7-9e29-4ca0-ad92-0bd08c1d15ec',
            url: '',
          },
        ],
      },
      pageDescriptors: {
        default: {
          icon: 'tpaPageType',
        },
        [SCHEDULE_SECTION_ID]: {
          icon: 'tpaPageType',
        },
      },
    },
  } as AppManifest
}
